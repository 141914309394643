import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import { wordList } from "../pages-components/first-letter-last-letter-game/top-1000-nouns.json";

function chooseNextWord(word: string) {
  console.log({ word });
  return "nextWord";
}

function GamePage() {
  const [userWord, setUserWord] = useState("");
  const [words, setWords] = useState([]);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    const computerWord = chooseNextWord(userWord);
    const newWords = [computerWord, userWord].concat(words);

    setWords(newWords);
    setUserWord("");
  };

  const handleTextChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setUserWord(event.target.value);
  };

  return (
    <>
      <form>
        <label>
          What's your word?
          <br />
          <input
            type="text"
            value={userWord}
            onChange={handleTextChange}
          ></input>
        </label>
        <button onClick={handleButtonClick}>Submit</button>
      </form>
      {words.map(word => {
        return <div>{word}</div>;
      })}
    </>
  );
}

const Page = () => {
  return (
    <Layout>
      <SEO title="First Letter Last Letter Game" />
      <div>
        <h1>First Letter Last Letter Game</h1>
      </div>
      <GamePage />
    </Layout>
  );
};

export default Page;
